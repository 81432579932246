/** @jsx jsx */
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { jsx, Container } from 'theme-ui';

const AuthenticatePage = () => {
  const { handleCallback } = useAuth();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const params = new URLSearchParams(window.location.search);

    if (!params.has('token') || !params.has('stytch_token_type')) {
      navigate('/account/signin');
      return;
    }

    handleCallback().then(() => navigate('/account'));
  }, []);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
      }}
    />
  );
};

export default AuthenticatePage;
