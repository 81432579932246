/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import AuthenticatePage from '~/components/Pages/Account/Authenticate';

const Authenticate = () => (
  <Layout hideWidget>
    <Metadata title="Signin" description="Signin to your August account" />
    <AuthenticatePage />
  </Layout>
);

export default Authenticate;
